import {SIMULATION_PARAMETERS_SET_VALUE} from "../../config/actions";

let defaultState = {
    Streckensprung: 0,
    US0: '', // Streckensprung == 1; Sprunghöhe in Spannung US
    q_st: '', // bei welcher Schubl�nge bzw. Winkel soll Sprung aufgeschaltet werden?
    Geschwindigkeitsregelung: 0,
    Lageregelung: 0,
    PI_GeschwRegler: 0,
    KP: '', // PI_GeschwRegler == 1; Verstärkung KP des PI Geschwindigkeitsreglers
    TN: '', // PI_GeschwRegler == 1; Nachstellzeit TN des PI Geschwindigkeitsreglers
    KI: '', // PI_GeschwRegler == 0; I-Teil KI des Redus Geschwindigkeitsreglers
    alpha: '', // PI_GeschwRegler == 0; Rückführung (Dämpfung) alpha des Redus Geschwindigkeitsreglers
    beta: '', // PI_GeschwRegler == 0; Vorsteuerung beta des Redus Geschwindigkeitsreglers,
    Sprung_v: 0, // Geschwindigkeitsregelung == 1; Falls Sprungantwort Geschwindigkeitsregelkreis eingegeben werden soll
    vs0: '', // Geschwindigkeitsregelung == 1 && Sprunv_v == 1; Sprunghöhe Geschwindigkeitssollwert
    t_lauf: '', // Geschwindigkeitsregelung == 1 && Sprunv_v == 1; Laufzeit der Simulation in Sekunden
    //q_st: '', // siehe oben; Geschwindigkeitsregelung == 1 && Sprunv_v == 0; Startkoordinate des Gelenkes in rad oder m
    q_z: '', // Geschwindigkeitsregelung == 1 && Sprunv_v == 0; Zielkoordinate des Gelenkes in rad oder m
    vm: '', // Geschwindigkeitsregelung == 1 && Sprunv_v == 0;
    bm: '', // Geschwindigkeitsregelung == 1 && Sprunv_v == 0;
    KL: '', // Geschwindigkeitsregelung == 0 && Lageregelung == 1; Verstärkung KL des Lagereglers
    KVor: '', // Geschwindigkeitsregelung == 0 && Lageregelung == 1; Geschwindigkeitsvorsteuerung
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SIMULATION_PARAMETERS_SET_VALUE: {
            return {
                ...state,
                [action.name]: action.value
            }
        }
        default:
            return state;
    }
}
