import React, {Fragment} from 'react';
import {connect} from "react-redux";
import Plotly from 'plotly.js-basic-dist';
import {setSimulationValue} from "../store/simulation/simulationAction";
import SimulationStrecke from "../math/SimulationStrecke";
import Bewegungsvorgabe from "../math/Bewegungsvorgabe";
import SimulationRegelung from "../math/SimulationRegelung";
import Streckenmodell from "../math/Streckenmodell";

class Simulation extends React.Component {

    doSimulation = () => {
        const {simulationParameters, setSimulationValue} = this.props;
        setSimulationValue('status', 'RUNNING');
        setSimulationValue('Streckensprung', simulationParameters.Streckensprung);
        setSimulationValue('Geschwindigkeitsregelung', simulationParameters.Geschwindigkeitsregelung);
        setSimulationValue('Lageregelung', simulationParameters.Lageregelung);

        window.setTimeout(() => {
            try {
                Streckenmodell();
                if (simulationParameters.Streckensprung === 1) {
                    SimulationStrecke();
                    setSimulationValue('status', 'SUCCESS');
                    this.renderStreckenantwort();
                } else {
                    Bewegungsvorgabe();
                    SimulationRegelung();
                    setSimulationValue('status', 'SUCCESS');
                    if (simulationParameters.Lageregelung !== 1) {
                        this.renderGeschwindigkeit();
                    } else {
                        this.renderLageregelung();
                    }
                }
            } catch (e) {
                console.error(e, e.message, e.stack);
                setSimulationValue('status', 'ERROR');
            }
        }, 100);
    };

    renderStreckenantwort = () => {
        const {simulation, routeParameters} = this.props;
        let plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.qp.toArray()[0],
            name: 'Streckenantwort',
            yaxis: 'y1'
        }, {x: simulation.Zeit.toArray(), y: simulation.US.toArray()[0], name: 'Eingangssprung', yaxis: 'y2'}];
        let layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: routeParameters.h !== 1 ? 'Geschwindigkeit v' : 'Winkelgeschwindigkeit v',
                rangemode: 'tozero'
            },
            yaxis2: {
                title: 'Stellwert US',
                overlaying: 'y',
                side: 'right'
            }
        };
        Plotly.newPlot('plot1', plotData, layout);
    };

    renderGeschwindigkeit = () => {
        const {simulation, routeParameters} = this.props;
        let plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.qsp_qp.toArray()[0],
            yaxis: 'y1',
            name: 'Soll'
        }, {
            x: simulation.Zeit.toArray(),
            y: simulation.qsp_qp.toArray()[1],
            yaxis: 'y1',
            name: 'Ist'
        }];
        let layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: routeParameters.h !== 1 ? 'Geschwindigkeit v' : 'Winkelgeschwindigkeit v',
                rangemode: 'tozero'
            }
        };
        Plotly.newPlot('plot1', plotData, layout);

        plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.US.toArray()[0],
            yaxis: 'y1'
        }];
        layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: 'Stellwert US',
                rangemode: 'tozero'
            }
        };
        Plotly.newPlot('plot2', plotData, layout);
    };

    renderLageregelung = () => {
        const {simulation, routeParameters} = this.props;
        let plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.qs_q.toArray()[0],
            name: 'Soll',
            yaxis: 'y1'
        },{
            x: simulation.Zeit.toArray(),
            y: simulation.qs_q.toArray()[1],
            name: 'Ist',
            yaxis: 'y1'
        }];
        let layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: 'q in ' + (routeParameters.h !== 1 ? 'm' : 'rad'),
                rangemode: 'tozero'
            }
        };
        Plotly.newPlot('plot1', plotData, layout);

        plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.qsp_qp.toArray()[0],
            yaxis: 'y1',
            name: 'Soll'
        }, {
            x: simulation.Zeit.toArray(),
            y: simulation.qsp_qp.toArray()[1],
            yaxis: 'y1',
            name: 'Ist'
        }];
        layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: 'v in ' + (routeParameters.h !== 1 ? 'm/sec' : 'rad/sec'),
                rangemode: 'tozero'
            }
        };
        Plotly.newPlot('plot2', plotData, layout);

        plotData = [{
            x: simulation.Zeit.toArray(),
            y: simulation.US.toArray()[0],
            name: 'Verhalten (Test) des Lageregelkreises',
            yaxis: 'y1'
        }];
        layout = {
            xaxis: {
                title: 'sec'
            },
            yaxis: {
                title: 'Stellwert US',
                rangemode: 'tozero'
            }
        };
        Plotly.newPlot('plot3', plotData, layout);
    };

    render() {
        const {simulation} = this.props;
        return (
            <div className="card">
                <div className="card-header">
                    <b>Simulation</b>
                </div>
                <div className="card-body">
                    <button className="btn btn-success" onClick={this.doSimulation}
                            disabled={simulation.status === 'RUNNING'}>
                        Simulation {['SUCCESS', 'ERROR'].includes(simulation.status) ? 'erneut ' : ''}starten
                    </button>
                    {simulation.status ? (
                        <Fragment>
                            <hr/>
                            {simulation.status === 'ERROR' ? (
                                <div className="text-danger">
                                    Ein Fehler ist aufgetreten.
                                </div>
                            ) : simulation.status === 'RUNNING' ? (
                                <div className="text-muted">
                                    Die Simulation läuft... <button className="btn btn-link"
                                                                    onClick={() => this.props.setSimulationValue('status', null)}>zurücksetzen</button>
                                </div>
                            ) : simulation.status === 'SUCCESS' ? (
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div id="plot1"/>
                                        </div>
                                        {simulation.Streckensprung !== 1 ? (
                                            <Fragment>
                                                <div className="col-12 mt-2">
                                                    <div id="plot2"/>
                                                </div>
                                                {simulation.Lageregelung === 1 ? (
                                                    <div className="col-12 mt-2">
                                                        <div id="plot3"/>
                                                    </div>
                                                ) : null}
                                            </Fragment>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    routeParameters: state.routeParameters,
    simulationParameters: state.simulationParameters,
    simulation: state.simulation
});

export default connect(mapStateToProps, {
    setSimulationValue
})(Simulation);
