import React, {Fragment} from 'react';
import {setRouteParameterValue} from "../store/routeParameters/routeParametersAction";
import {connect} from "react-redux";
import VariableHelp from "./VariableHelp";

class RouteParameters extends React.Component {
    render() {
        const { routeParameters, setRouteParameterValue } = this.props;
        return (
            <div className="card">
                <div className="card-header">
                    <b>Parameter der Regelstrecke</b>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="form-group col-6">
                            <label>
                                Typ der Achse
                            </label>
                            <select className="form-control form-control-sm" value={routeParameters.h} onChange={(e) => setRouteParameterValue('h', parseInt(e.target.value))}>
                                <option value="1">rotatorische Achse</option>
                                <option value="2">translatorische Achse</option>
                            </select>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    g
                                </label>
                            </div>
                            <input type="text" value={routeParameters.g} onChange={(e) => setRouteParameterValue('g', e.target.value)} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <hr/>
                    {routeParameters.h === 1 ? (
                        <Fragment>
                            <div className="row">
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Trägheitsmoment um rotatorische Achse"/>
                                        <label>
                                            JL
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.JL} onChange={(e) => setRouteParameterValue('JL', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">kgm<sup>2</sup></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Masse des Armteils"/>
                                        <label>
                                            m
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.m} onChange={(e) => setRouteParameterValue('m', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">kg</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Abstand Achse zu Massenmittelpunkt der Achse"/>
                                        <label>
                                            ls
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.ls} onChange={(e) => setRouteParameterValue('ls', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Haftreibungsmoment im Gelenk (Schwelle)"/>
                                        <label>
                                            MRG0
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.MRG0} onChange={(e) => setRouteParameterValue('MRG0', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">Nm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <div>
                                        <VariableHelp text="Reibungskoeffizient der geschw.abhängigen Reibung im Gelenk"/>
                                        <label>
                                            FD
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.FD} onChange={(e) => setRouteParameterValue('FD', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">Nmsec/rad</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="row">
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Masse des Gelenks"/>
                                        <label>
                                            m
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.m} onChange={(e) => setRouteParameterValue('m', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">kg</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Haftreibungskraft im Gelenk (Schwelle)"/>
                                        <label>
                                            MRG0
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.MRG0} onChange={(e) => setRouteParameterValue('MRG0', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">N</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Reibungskoeffizient der geschw.abhängigen Reibung im Gelenk"/>
                                        <label>
                                            FD
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.FD} onChange={(e) => setRouteParameterValue('FD', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">Nsec/m</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Neigungswinkel der Linearachse gegenüber der Horizontalen"/>
                                        <label>
                                            beta_neig
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={routeParameters.beta_neig} onChange={(e) => setRouteParameterValue('beta_neig', e.target.value)} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">rad</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    <hr/>
                    <div className="row">
                        <div className="form-group col-6">
                            <div>
                                <VariableHelp text="Trägheitsmoment Motoranker"/>
                                <label>
                                    JA
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.JA} onChange={(e) => setRouteParameterValue('JA', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">kgm<sup>2</sup></span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-6">
                            <div>
                                <VariableHelp text="Reibungskoeffizient der geschw.abhängigen Reibung des Motorankers"/>
                                <label>
                                    FM
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.FM} onChange={(e) => setRouteParameterValue('FM', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nmsec/rad</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Motorkonstante"/>
                                <label>
                                    C
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.C} onChange={(e) => setRouteParameterValue('C', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Haftreibungsmoment Motor"/>
                                <label>
                                    MR0
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.MR0} onChange={(e) => setRouteParameterValue('MR0', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Messkonstante zwischen momentenbildendem Strom und Spannung"/>
                                <label>
                                    KMI
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.KMI} onChange={(e) => setRouteParameterValue('KMI', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">V/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Untersetzung"/>
                                <label>
                                    u
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.u} onChange={(e) => setRouteParameterValue('u', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">{routeParameters.h === 1 ? '1' : 'rad/m'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    routeParameters: state.routeParameters
});

export default connect(mapStateToProps, {
    setRouteParameterValue
})(RouteParameters);
