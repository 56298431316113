import {compose, createStore} from "redux";
import simulationEingelenkregelung from './store/rootReducer';
import persistState from 'redux-localstorage';

const enhancer = compose(
    persistState(null, {key: 'simulation-eingelenkregelung'})
);

const store = createStore(simulationEingelenkregelung, enhancer);

export {store};
