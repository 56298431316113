import React, {Fragment} from 'react';
import {setSimulationParameterValue} from "../store/simulationParameters/simulationParametersAction";
import {connect} from "react-redux";
import VariableHelp from "./VariableHelp";

class SimulationParameters extends React.Component {

    render() {
        const {simulationParameters, setSimulationParameterValue, routeParameters} = this.props;
        return (
            <Fragment>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-12">
                                <div className="form-check checkbox">
                                    <input className="form-check-input" id="Sprungantwort" type="checkbox"
                                           checked={simulationParameters.Streckensprung === 1}
                                           onChange={(e) => setSimulationParameterValue('Streckensprung', e.target.checked ? 1 : 0)}/>
                                    <label className="form-check-label" htmlFor="Sprungantwort">Simulation der Strecke
                                        (Sprungantwort)</label>
                                </div>
                            </div>
                        </div>
                        {simulationParameters.Streckensprung === 1 ? (
                            <div className="row">
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Sprunghöhe"/>
                                        <label>
                                            US0
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={simulationParameters.US0}
                                               onChange={(e) => setSimulationParameterValue('US0', e.target.value)}
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp
                                            text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                        <label>
                                            q_st
                                        </label>
                                    </div>
                                    <div className="input-group input-group-sm">
                                        <input type="text" value={simulationParameters.q_st}
                                               onChange={(e) => setSimulationParameterValue('q_st', e.target.value)}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span
                                                className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {simulationParameters.Streckensprung !== 1 ? (
                    <div className="card mt-2">
                        <div className="card-header">
                            <b>Struktur und Parameter der Regelung</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <div className="form-check checkbox">
                                        <input className="form-check-input" id="GeschSpr" type="checkbox"
                                               checked={simulationParameters.Lageregelung === 0 && simulationParameters.Sprung_v === 1}
                                               onChange={(e) => {setSimulationParameterValue('Lageregelung', e.target.checked ? 0 : 1); setSimulationParameterValue('Sprung_v', e.target.checked ? 1 : 0)} }/>
                                        <label className="form-check-label" htmlFor="GeschSpr">Nur Geschwindigkeitsregelung simulieren (Sprungantwort)</label>
                                    </div>
                                    <div className="form-check checkbox">
                                        <input className="form-check-input" id="GeschRam" type="checkbox"
                                               checked={simulationParameters.Lageregelung === 0 && simulationParameters.Sprung_v === 0}
                                               onChange={(e) => {setSimulationParameterValue('Lageregelung', e.target.checked ? 0 : 1); setSimulationParameterValue('Sprung_v', e.target.checked ? 0 : 1)} }/>
                                        <label className="form-check-label" htmlFor="GeschRam">Nur Geschwindigkeitsregelung simulieren (Rampenantwort)</label>
                                    </div>
                                    <div className="form-check checkbox">
                                        <input className="form-check-input" id="Lageregelung" type="checkbox"
                                               checked={simulationParameters.Lageregelung === 1 && simulationParameters.Sprung_v === 0}
                                               onChange={(e) => {setSimulationParameterValue('Lageregelung', e.target.checked ? 1 : 0); setSimulationParameterValue('Sprung_v', e.target.checked ? 0 : 1)} }/>
                                        <label className="form-check-label" htmlFor="Lageregelung">Lageregelung simulieren</label>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            {simulationParameters.Sprung_v === 1 ? (
                                <div className="row">
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Sprunghöhe Geschwindigkeitssollwert"/>
                                            <label>
                                                vs0
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.vs0}
                                                   onChange={(e) => setSimulationParameterValue('vs0', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}/sec</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Ab welcher Gelenkkoordinate soll die Bewegung beginnen?"/>
                                            <label>
                                                q_st
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.q_st}
                                                   onChange={(e) => setSimulationParameterValue('q_st', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Laufzeit der Simulation"/>
                                            <label>
                                                t_lauf
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.t_lauf}
                                                   onChange={(e) => setSimulationParameterValue('t_lauf', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">sec</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Startkoordinate des Gelenkes"/>
                                            <label>
                                                q_st
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.q_st}
                                                   onChange={(e) => setSimulationParameterValue('q_st', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Zielkoordinate des Gelenkes"/>
                                            <label>
                                                q_z
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.q_z}
                                                   onChange={(e) => setSimulationParameterValue('q_z', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Geschwindigkeit Rampenbahn"/>
                                            <label>
                                                vm
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.vm}
                                                   onChange={(e) => setSimulationParameterValue('vm', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}/sec</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp text="Beschleunigung Rampenbahn"/>
                                            <label>
                                                bm
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.bm}
                                                   onChange={(e) => setSimulationParameterValue('bm', e.target.value)}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">{routeParameters.h !== 1 ? 'm' : 'rad'}/sec<sup>2</sup></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr/>
                            <div className="row">
                                <div className="form-group col-3">
                                    <div>
                                        <VariableHelp text="Geschwindigkeitsregler"/>
                                        <label>
                                            Regler
                                        </label>
                                    </div>
                                    <select className="form-control form-control-sm"
                                            value={simulationParameters.PI_GeschwRegler}
                                            onChange={(e) => setSimulationParameterValue('PI_GeschwRegler', parseInt(e.target.value))}>
                                        <option value="0">Redus-Regler</option>
                                        <option value="1">PI-Regler</option>
                                    </select>
                                </div>
                                {simulationParameters.PI_GeschwRegler === 1 ? (
                                    <Fragment>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Verstärkung"/>
                                                <label>
                                                    KP
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.KP}
                                                       onChange={(e) => setSimulationParameterValue('KP', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Nachstellzeit"/>
                                                <label>
                                                    TN
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.TN}
                                                       onChange={(e) => setSimulationParameterValue('TN', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="I-Teil"/>
                                                <label>
                                                    KI
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.KI}
                                                       onChange={(e) => setSimulationParameterValue('KI', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Rückfünrung (Dämpfung)"/>
                                                <label>
                                                    alpha
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.alpha}
                                                       onChange={(e) => setSimulationParameterValue('alpha', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Vorsteuerung"/>
                                                <label>
                                                    beta
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.beta}
                                                       onChange={(e) => setSimulationParameterValue('beta', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                            <hr/>
                            <div className="row">
                                {simulationParameters.Lageregelung === 1 ? (
                                    <Fragment>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Verstärkung"/>
                                                <label>
                                                    KL
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.KL}
                                                       onChange={(e) => setSimulationParameterValue('KL', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <div>
                                                <VariableHelp text="Geschwindigkeitsvorsteuerung"/>
                                                <label>
                                                    KVor
                                                </label>
                                            </div>
                                            <div className="input-group input-group-sm">
                                                <input type="text" value={simulationParameters.KVor}
                                                       onChange={(e) => setSimulationParameterValue('KVor', e.target.value)}
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : null}
                            </div>

                        </div>
                    </div>
                ) : null}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    routeParameters: state.routeParameters,
    simulationParameters: state.simulationParameters
});

export default connect(mapStateToProps, {
    setSimulationParameterValue
})(SimulationParameters);
