import {ROUTE_PARAMETERS_SET_VALUE} from "../../config/actions";

let defaultState = {
    h: 1, // 1: rotatorische Achse, 2: translatorische Achse
    g: '9.81', // Wert der Gravitationskonstante
    JL: '', // h == 1; Trägkeitsmoment um rotatorische Achse in kgm
    m: '', // Masse des Gelenks in kg
    ls: '', // h == 1; Abstand Achse zu Massenmittelpunkt der Achse in m
    MRG0: '', // Haftreibungsmoment im Gelenk (Schwelle) in Nm
    FD: '', // Reibungskoeffizient der geschw.abhängigen Reibung im Gelenk, Einheit Nmsec/rad
    beta_neig: '', // Neigungswinkel der Linearachse gegen�ber der Horizontalen in rad
    JA: '', // Trägheitsmoment Motoranker etc in kgm
    C: '', // Motorkonstante in Nm/A
    MR0: '', // Haftreibungsmoment Motor in Nm
    FM: '', // Reibungskoeffizient der geschw.abhängigen Reibung des Motorankers, Einheit Nmsec/rad
    KMI: '', // Messkonstante zwischen momentenbildendem Strom und Spannung in V/A
    u: '', // Untersetzung in, Einheit 1 (Rotationsachse), Einheit rad/m (Translationsachse)
    eps: '0.002'
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ROUTE_PARAMETERS_SET_VALUE: {
            return {
                ...state,
                [action.name]: action.value
            }
        }
        default:
            return state;
    }
}
